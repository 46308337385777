import { createHandler } from '@/util/events.js'
import Tracker from '@/util/tracker.js'

const LOADED_EVENT = 'vehicle compatibility loaded'
const UPDATED_EVENT = 'vehicle compatibility updated'
const RESULT_EVENT = 'vehicle compatibility result'

const isCompatible = (year, make, fuelType) => {
  const data = document.querySelector('.vehicle-compatibility-form').dataset
  const minYear = JSON.parse(data.year)

  if (year === '' || make === '' || fuelType === '') return null
  if (year < minYear.min) return false
  if (fuelType === 'Diesel' && year < minYear.diesel) return false
  if (fuelType === 'Electric') return false
  if (fuelType === 'Hybrid' && year < minYear.hybrid) return false
  return true
}

createHandler('vehicleCompatibilityLoaded', function () {
  const country = this.dataset.country
  const clean_url = `${window.location.origin.toString()}${window.location.pathname.toString()}`

  Tracker.track(LOADED_EVENT, {
    country,
    clean_url,
  })
})

createHandler('vehicleCompatibilityUpdated', function () {
  const { name, value } = this
  const country = this.dataset.country

  Tracker.track(UPDATED_EVENT, {
    name,
    value,
    country,
  })
})

createHandler('vehicleCompatibility', function () {
  const resultElement = document.querySelector('.compatibility-results')
  const compatibleText = resultElement.querySelector('.compatible')
  const nonCompatibleText = resultElement.querySelector('.not-compatible')
  const ctaContainer = document.querySelector('.cta-compatibility-container')
  let compatible = null
  const year = this.querySelector('.year-select').value
  const make = this.querySelector('.make-select').value
  const fuelType = this.querySelector('.fuel-type-select').value

  compatible = isCompatible(year, make, fuelType)

  if (compatible === true) {
    resultElement.classList.add('text-green')
    compatibleText.classList.remove('hidden')
    nonCompatibleText.classList.add('hidden')
    ctaContainer.classList.remove('hidden')
  } else if (compatible === false) {
    resultElement.classList.remove('text-green')
    nonCompatibleText.classList.remove('hidden')
    compatibleText.classList.add('hidden')
    ctaContainer.classList.add('hidden')
  }

  if (compatible !== null) {
    const country = this.dataset.country
    Tracker.track(RESULT_EVENT, {
      result: compatible ? 'compatible' : 'not compatible',
      country,
    })
  }
})
