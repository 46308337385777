<script>
  import Section from '@/components/landing-page/widgets/Section.svelte'
  import QuestionAccordion from '@/components/QuestionAccordion.svelte'
  import Question from '@/components/Question.svelte'

  export let background = null
</script>

<style>
  .color-container {
    background: linear-gradient(to right, rgb(90 107 125), rgb(81 97 107));
  }

  .shadow {
    box-shadow: 0 4px 4px 0 rgb(0 0 0 / 25%);
  }
</style>

<Section
  center={true}
  verticalPadding="none"
  horizontalPadding="none"
  maxWidth="2xl"
>
  <div class="py-11 md:px-16" class:color-container={!background}>
    <slot name="header">
      <div class="mb-8">
        <h1 class:text-white={!background}>Frequently Asked Questions</h1>
      </div>
    </slot>

    <div
      class="bg-white px-6 md:px-20 mx-3 md:w-3/5 md:mx-auto py-6 md:py-16 rounded-3xl shadow"
    >
      <QuestionAccordion>
        <Question
          title="Does FIXD work with my car?"
          additionTitleClass="text-black text-base md:text-xl"
        >
          <p class="text-black">
            FIXD works with all gas-powered cars and trucks built after 1996 and
            diesels built after 2008. The FIXD Sensor and app can read all
            standardized OBD2 codes on most gas-powered vehicles built in 1996
            or newer. Enhanced codes such as ABS, airbag, transmission, & TPMS
            are NOT supported.
          </p>
        </Question>
        <Question
          title="Is it easy to use?"
          additionTitleClass="text-black text-base md:text-xl"
        >
          <p class="text-black">
            YES! The great thing about FIXD is that it's literally made for ease
            and simplicity. Anyone can install it in their car themselves -
            without tools or going to a mechanic. And once you connect it to the
            free FIXD app on your smartphone, it tells you in plain, simple
            English what's wrong with your car and how severe the issue is so
            you can make an informed decision. You can even connect multiple
            sensors to one phone, allowing you to monitor all your family
            members' vehicles from one convenient place.
          </p>
          <p class="text-black">
            If for some reason you have any questions or trouble installing it,
            simply email us at
            <a href="https://www.fixd.com/help" class="underline text-green"
              >send us a message here</a
            >.
          </p>
        </Question>
        <Question
          title="What kind of information can FIXD tell me about my car?"
          additionTitleClass="text-black text-base md:text-xl"
        >
          <p class="text-black">
            The FIXD Sensor and free app can tell you what your check engine
            light means in simple terms, how serious the issue is and
            consequences of continued driving, and when it's time for routine
            maintenance. FIXD reads over 7000 standardized OBD2 check engine
            codes; however, it is unable to read enhanced codes (such as TPMS,
            ABS, airbags, & transmission) at this time.
          </p>
        </Question>
        <Question
          title="Is FIXD compatible with iPhone and Android smartphones?"
          additionTitleClass="text-black text-base md:text-xl"
        >
          <p class="text-black">
            YES! The free FIXD app is compatible with both iPhones and Android
            smartphones. It only takes a few minutes to set up and start
            monitoring your car's health around the clock, preventing $1000s in
            costly repairs and giving you more confidence and control at the
            repair shop.
          </p>
        </Question>
        <Question
          title="Do I have to pay a subscription?"
          additionTitleClass="text-black text-base md:text-xl"
        >
          <p class="text-black">
            Nope. The FIXD Sensor and free app are 100% free to use as many
            times as you'd like, on as many vehicles as you want. IF you'd like
            to check out our features such as cost estimates and the Mechanic
            Hotline, you can try FIXD Premium.
          </p>
        </Question>
        <Question
          title="What is FIXD Premium?"
          additionTitleClass="text-black text-base md:text-xl"
        >
          <p class="text-black">
            FIXD Premium is the expanded version of our free FIXD app that
            delivers accurate cost estimates for your specific repairs, gives
            you access to on-call FIXD Mechanics and DIY assistance, and helps
            you handle any car issue with confidence. This upgrade is 100%
            optional and not required to use the FIXD Sensor for diagnosing your
            check engine light. Learn more about <a
              href="https://www.fixd.com/premium/home"
              class="underline text-green">FIXD Premium here</a
            >.
          </p>
        </Question>
      </QuestionAccordion>
    </div>

    <slot name="cta" />
  </div>
</Section>
