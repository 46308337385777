<script>
  import QuestionAccordion from '@/components/QuestionAccordion.svelte'
  import Question from '@/components/Question.svelte'
  import Section from '@/components/landing-page/widgets/Section.svelte'

  export let darkTheme = false
</script>

<Section verticalPadding="short">
  <div class="shadow py-5 md:py-14 pl-[2vw] rounded-2xl">
    <slot name="header">
      <div class="ml-4">
        <p class:text-white={darkTheme} class="text-lg">FAQ</p>
      </div>
    </slot>

    <QuestionAccordion>
      <Question
        title="Does FIXD work with my car?"
        {darkTheme}
        titleSize="lg"
        titleWeightClass="font-normal"
      >
        <p class="text-sm">
          FIXD works with all gas-powered cars and trucks built after 1996 and
          diesels built after 2008. The FIXD Sensor and app can read all
          standardized OBD2 codes on most gas-powered vehicles built in 1996 or
          newer. Enhanced codes such as ABS, airbag, transmission, & TPMS are
          NOT supported.
        </p>
      </Question>
      <Question
        title="Is it easy to use?"
        {darkTheme}
        titleSize="lg"
        titleWeightClass="font-normal"
      >
        <p class="text-sm">
          YES! The great thing about FIXD is that it’s literally made for ease
          and simplicity. Anyone can install it in their car themselves -
          without tools or going to a mechanic. And once you connect it to the
          free FIXD app on your smartphone, it tells you in plain, simple
          English what’s wrong with your car and how severe the issue is so you
          can make an informed decision. You can even connect multiple sensors
          to one phone, allowing you to monitor all your family members'
          vehicles from one convenient place.
        </p>
        <p class="text-sm">
          If for some reason you have any questions or trouble installing it,
          simply email us at
          <a href="https://www.fixd.com/help" class="underline text-green"
            >fixdapp.com/help!</a
          >.
        </p>
      </Question>
      <Question
        title="Can it really tell me what's wrong with my car?"
        {darkTheme}
        titleSize="lg"
        titleWeightClass="font-normal"
      >
        <p class="text-sm">
          You bet. FIXD gives you the plain English readout of over 10,000 codes
          that your check engine light gives you. It only takes a couple minutes
          to install, and once it’s set up, you’ll have up-to-date information
          about your car at your fingertips on your smartphone.You can use this
          information to be more informed, knowledgeable, and confident at the
          auto shop so you don’t get ripped off or end up paying for repairs you
          don’t need.
        </p>
      </Question>
      <Question
        title="Is it available on Android and Apple phones?"
        {darkTheme}
        titleSize="lg"
        titleWeightClass="font-normal"
      >
        <p class="text-sm">
          YES! The free FIXD app is compatible with both iPhones and Android
          smartphones. It only takes a few minutes to set up and start
          monitoring your car's health around the clock, preventing $1000s in
          costly repairs and giving you more confidence and control at the
          repair shop.
        </p>
      </Question>
      <Question
        title="Is there any benefit to ordering today?"
        {darkTheme}
        titleSize="lg"
        titleWeightClass="font-normal"
      >
        <p class="text-sm">
          YES! Right now on this page, FIXD is offering deep discounts on their
          money-saving, stress-reducing diagnostic device. Supplies are limited,
          so make sure you order now before we run out!
        </p>
      </Question>
    </QuestionAccordion>

    <slot name="cta" />
  </div>
</Section>
