import { createHandler } from '@/util/events.js'

let warrantySelected = false
let checkoutURL = ''
createHandler('initialFunnelLoad', function () {
  updateCheckoutInfo()
})

createHandler('redirectToCheckout', function () {
  window.location.href = checkoutURL
})

createHandler('changePackQuantity', function () {
  if (this.classList.contains('selected')) {
    return
  }

  const warrantyInput = document.querySelector('#warrantyInput')
  const newPrice = warrantyInput?.checked
    ? (
        parseFloat(this.dataset.subtotalPrice) +
        parseFloat(warrantyInput.dataset.warrantyPrice)
      ).toFixed(2)
    : this.dataset.subtotalPrice

  document.querySelector('.subtotal-price').innerHTML =
    updateSalesPrice(newPrice)

  document.querySelectorAll('.pack-button.selected').forEach((element) => {
    element.classList.remove('selected')
  })
  this.classList.add('selected')
  updateStrikethroughPrice()
  updateGooglePayPriceAmount(newPrice)
  updateCheckoutInfo()
  updateBuyBoxSensorImage(this.dataset.quantity)
})

createHandler('changeWarranty', function () {
  const warrantyInput = document.querySelector('#warrantyInput')
  const subtotalPrice = document.querySelector('.pack-button.selected').dataset
    .subtotalPrice
  const subtotalPriceElement = document.querySelector('.subtotal-price')
  const warrantyPrice = this.dataset.warrantyPrice

  warrantySelected = warrantyInput.checked
  const newSubtotalPrice = warrantySelected
    ? Number(parseFloat(subtotalPrice) + parseFloat(warrantyPrice)).toFixed(2)
    : Number(parseFloat(subtotalPrice)).toFixed(2)

  subtotalPriceElement.innerHTML = updateSalesPrice(newSubtotalPrice)
  updateStrikethroughPrice()
  updateGooglePayPriceAmount(newSubtotalPrice)
  updateCheckoutInfo()
})

const updateStrikethroughPrice = () => {
  const strikethroughPrice = document.querySelector('.pack-button.selected')
    .dataset.strikethroughPrice
  const warrantyInput = document.querySelector('#warrantyInput')
  const newPrice = warrantyInput?.checked
    ? (
        parseFloat(strikethroughPrice) +
        parseFloat(warrantyInput.dataset.warrantyPrice)
      ).toFixed(2)
    : strikethroughPrice

  document.querySelector('.strikethrough-price').innerHTML = Math.round(
    newPrice.toString(),
  )
}

createHandler('switchThumbnail', function (ev) {
  const { index } = ev.detail
  highlightThumbnail(
    document.querySelectorAll('.thumbnail-container'),
    index - 1,
  )
})

const convertUsCurrencyFormatToEurope = (price) => {
  // TODO: FIXME: Quick fix to update French pricing
  const changeCurrencySeparatorToComma = location.pathname
    .toLowerCase()
    .includes('fr-ca')

  return changeCurrencySeparatorToComma ? price.replace('.', ',') : price
}

createHandler('thumbnailClicked', function () {
  const carousel = document.querySelector('#main-carousel')?.splide
  if (carousel == null) {
    return
  }

  const index = parseInt(this.dataset.index, 10)
  highlightThumbnail(document.querySelectorAll('.thumbnail-container'), index)
  carousel.go(index + 1)
})

// Helper functions

const updateSalesPrice = (newPrice) => {
  const initialOfferPrice = document.querySelector(
    '.pack-button[data-quantity="1pk"]',
  ).dataset.subtotalPrice

  return parseFloat(newPrice) < parseFloat(initialOfferPrice)
    ? convertUsCurrencyFormatToEurope(initialOfferPrice)
    : convertUsCurrencyFormatToEurope(newPrice)
}

function highlightThumbnail(container, index) {
  const strindex = index.toString()
  container.forEach((item) => {
    item.querySelectorAll('[data-index]').forEach((element) => {
      element.classList.toggle(
        'border-green',
        element.dataset.index === strindex,
      )
    })
  })
}

const updateBuyBoxSensorImage = (quantity) => {
  document.querySelectorAll('.buybox-sensor-image').forEach((image) => {
    if (image.classList.contains(quantity)) {
      image.classList.remove('hidden')
    } else {
      image.classList.add('hidden')
    }
  })
}

const updateGooglePayPriceAmount = (amount) => {
  document.querySelector('googlepay-button')?.setAttribute('totalprice', amount)
}

const updateCheckoutInfo = () => {
  const selectedOffer = document.querySelector('.pack-button.selected')?.dataset
    ?.offerSlug
  if (selectedOffer == null) {
    return
  }

  const funnelSlug = document.querySelector('.buy-box')?.dataset?.funnel
  if (funnelSlug == null) {
    return
  }

  const selectedOfferWithWarranty = document.querySelector(
    '.pack-button.selected',
  )?.dataset?.offerWarrantySlug

  const warrantyInput = document?.querySelector('#warrantyInput')
  const formActionUrl = document.querySelector('#expressCheckout')

  const newOfferSlug = warrantyInput?.checked
    ? selectedOfferWithWarranty
    : selectedOffer
  const newCheckoutURL = `/buy/${funnelSlug}/${newOfferSlug}`

  if (formActionUrl) {
    formActionUrl.action = newCheckoutURL
  }
  checkoutURL = newCheckoutURL
}

// FIXME: This needs to be fixed
// const uncheckWarrantyOnStart = () => {
//   // When doing back from the checkout, if the user purchased it with warranty input
//   // the page is loaded with this as checked
//   document.querySelector('#warrantyInput').checked = false
//   document.querySelector('#warrantyInput').defaultChecked = false
// }

// Whoop Testing

createHandler('changePackQuantityWithQuantity', function () {
  const increase = this.dataset.type === '+'
  const quantityAmount = parseInt(
    document.querySelector('.quantity-amount').innerHTML,
  )
  let newAmount

  increase
    ? (newAmount = Math.min(parseInt(quantityAmount) + 1, 3))
    : (newAmount = Math.max(1, parseInt(quantityAmount) - 1))

  if (newAmount <= 3 && newAmount >= 1) {
    document.querySelector('.quantity-amount').innerHTML = newAmount
  }

  const newPriceInfo = JSON.parse(this.dataset[`pack${newAmount}`])

  const { quantity, subtotalPrice, offerSlug, strikethroughPrice } =
    newPriceInfo

  const warrantyInput = document.querySelector('#warrantyInput')
  const newPrice = warrantyInput?.checked
    ? (
        parseFloat(subtotalPrice) +
        parseFloat(warrantyInput.dataset.warrantyPrice)
      ).toFixed(2)
    : subtotalPrice

  document.querySelector('.subtotal-price').innerHTML = newPrice
  document.querySelector('.strikethrough-price').innerHTML = strikethroughPrice

  const formActionUrl = document.querySelector('#expressCheckout')
  const funnelSlug = document.querySelector('.buy-box')?.dataset?.funnel

  const newCheckoutURL = `/buy/${funnelSlug}/${offerSlug}`

  if (formActionUrl) {
    formActionUrl.action = newCheckoutURL
  }

  checkoutURL = `/buy/${offerSlug}/${offerSlug}${warrantyInput?.checked ? 'w' : ''}`

  updateGooglePayPriceAmount(subtotalPrice)
  updateBuyBoxSensorImage(quantity)
})

createHandler('changeWarrantyWithQuantity', function () {
  const warrantyInput = document.querySelector('#warrantyInput')

  const subtotalPriceElement = document.querySelector('.subtotal-price')
  const packQuantity = document.querySelector('.quantity-amount').innerHTML

  const amounts = document.querySelector('.quantity-decrease')

  const offerInfo = JSON.parse(amounts.dataset[`pack${packQuantity}`])

  const { subtotalPrice, offerSlug } = offerInfo

  const warrantyPrice = this.dataset.warrantyPrice

  warrantySelected = warrantyInput.checked
  const newSubtotalPrice = warrantySelected
    ? Number(parseFloat(subtotalPrice) + parseFloat(warrantyPrice)).toFixed(2)
    : Number(parseFloat(subtotalPrice)).toFixed(2)

  const initialOfferPrice = JSON.parse(amounts.dataset.pack1).subtotalPrice

  subtotalPriceElement.innerHTML =
    initialOfferPrice < newSubtotalPrice ? newSubtotalPrice : initialOfferPrice

  checkoutURL = `/buy/${offerSlug}/${offerSlug}${warrantySelected ? 'w' : ''}`

  updateGooglePayPriceAmount(newSubtotalPrice)
  updateCheckoutInfo()
})
